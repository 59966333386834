.ReactModal__Overlay{
    &{
        max-width: 100%;
        background-color: rgba(0,0,0,0.7) !important;
        z-index: 999;
    }
    .ReactModal__Content{
        &{
            box-shadow: 0 0 30px 10px rgba(0,0,0,0.7);
        }
    }
}
#video360{
    &{
        width: 100%;
        display: block;
        position: relative;
    }
    .close{
        &{
            padding: 0.5rem;
            color: #FFF;
            font-size: 14px;
            border-radius: 10px;
            background-color: #ffc500;
            box-shadow: 0 6px #b98c00;
            position: relative;
            border: 0;
            cursor: pointer;
            position: absolute;
            top: -0.5rem;
            right: 0rem;
            text-transform: uppercase;
            font-weight: bold;
        }
        &:hover{
            top: -0.25rem;
            box-shadow: 0 2px #b98c00;
        }
    }
    p{
        &{
            width: 100%;
            display: block;
            margin: {
                bottom: 2rem;
            }
            text-align: center;
            text-transform: uppercase;
        }
    }
    iframe{
        &{
            max-width: 100%;
        }
    }
}
@media screen and (max-width: 480px) {
    #video360 {
        p{
            &{
                padding: {
                    right: 5rem;
                }
                text-align: left;
            }
        }
    }
}