#gallery{
    &{
        width: 100%;
        display: block;
        margin: 2rem 0;
    }
    h2{
        &{
            display: block;
            text-align: center;
            color: #04103e;
            font-size: 52px;
            font-family: 'Playfair Display SC', serif;
            font-weight: normal;
        }
    }
    .galeria {
        & {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 3rem;
        }

        .column {
            & {
                display: inline-flex;
                flex-direction: column;
                justify-content: space-between;
            }

            figure {
                & {
                    margin-bottom: 1rem;
                }
                img{
                    &{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 999px) {
    #gallery {  
        .galeria {
            .column {
                figure {
                    & {
                        margin: .5rem;
                    }

                    img {
                        & {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #gallery {  
        &{
            padding: 1rem 0;
        }
        h2{
            &{
                font-size: 24px;
            }
        }  
        .galeria {
            & {
                flex-direction: column;
                align-items: center;
                margin: {
                    top: 1rem;
                }
            }
            .column{
                &{
                    width: 100%;
                }
                figure{
                    img{
                        &{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}