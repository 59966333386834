#aside-scroll {
  & {
    position: fixed;
    bottom: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-align: center;
      width: 150px;
      height: 80px;
      padding: 15px 14px 0px 14px;
      cursor: pointer;
      display: table;
      float: left;

      cursor: pointer;
    }
    small {
      color: #fff;
      font-size: 13px;
      font-family: Mulish, sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 0.3rem;
      display: table-cell;
      text-align: left;
      vertical-align: middle;
    }
    &.chat-anchor {
      & {
        background-color: #04103e;
        border-top-left-radius: 20px;
        transition: background-color 0.2s;
      }

      &:hover {
        background-color: #0a2aa6;
      }
    }
    &.whatsapp {
      & {
        background-color: #3a8f10;
        transition: background-color 0.2s;
        border-top-left-radius: 20px;
      }

      &:hover {
        background-color: #4dbe15;
      }
    }
    &.corretor {
      & {
        background-color: #ffc500;
        border-top-right-radius: 20px;
        transition: background-color 0.2s;
      }
      &:hover {
        background-color: #ffd33f;
      }
      small {
        color: #04103e;
        margin-top: -1rem;
      }
    }
  }
}
@media screen and (max-width: 999px) {
  #aside-scroll {
    & {
      height: auto;
      top: unset;
      width: 100%;

      flex-direction: row-reverse;
    }
    a {
      & {
        width: 100%;
      }
      &.chat-anchor {
        border-top-left-radius: 0;
        border-top-right-radius: 20px;
      }
      &.corretor {
        & {
          border-bottom-left-radius: 0;
          border-top-left-radius: 20px;
        }
      }
    }
  }
}
