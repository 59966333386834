.history {
    & {
        width: 100%;
        display: block;
        padding: 11rem 0 3rem 0;
        text-align: center;
        max-width: 1212px;
        margin: auto;
        font-family: "Open Sans",sans-serif;
    }

    &__title {
            width: 100%;
    display: block;
        font-size: 42px;
        font-family: 'Trajan';
        // font-weight: 900;
        color: #04103e;

    }

    &__text {
        & {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 2rem 0;
        }

        p {
            width: 48%;
            display: block;
            color: #75776c;
            font-size: 16px;
            line-height: 1.5;
            text-align: justify;
        }
    }

    &__awards {
        & {
                width: 100%;
                display: block;
        }

        img{
            &{
                max-width: 100%;
            }
        }

        &-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            border: {
                top: 1px solid lightgray;
                bottom: 1px solid lightgray;
            }

            ;
            padding: 1rem 0;
            margin: 1rem 0;
        }
    }

    &__subtitle {
            width: 100%;
    display: block;
        color: #3a4d66;
        font-size: 20px;
        font-family: 'Cinzel', serif;
        text-align: left;
    }

    &__anchor {
        & {
                width: 100%;
    display: block;
            text-align: center;
            margin: {
                top: 2rem;
            }
        }

        a {
            & {
                color: #3a4d66;
                font-weight: bold;
                font-size: 18px;
                text-decoration: none;
            }
            &::after{
                content: '';
                width: 40px;
                height: 50px;
                margin: 1rem auto 0 auto;
                display: block;
                animation: float 6s ease-in-out infinite;
                background-image: url('/images/history__anchor--click.png')
            }
        }
    }
}

@media (max-width:999px) {
    .history {
        padding: 6rem 2rem 2rem;
        box-sizing: border-box;
        
    }
}

@media (max-width:600px) {
    .history {
        &{
            // margin: 1rem 0;  
        }
        &__title {
            & {
                font-size: 21px;
            }
        }
        &__text{
            &{
                flex-direction: column;
            }
            p{
                width: 100%;
                font-size: 14px;
            }
        }
        &__awards{
            &-list{
                flex-direction: column;
            }
        }
    }
}