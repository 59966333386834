.chat-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10001;
    padding: 0 1rem;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    box-sizing: border-box;
    font-family: 'Mulish', sans-serif;
    
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        border-radius: 10px;
        width: 100%;
        max-width: 660px;

        .modal-close {
            display: none;
        }
        
        .modal-header {
            background: url('../images/scroll-modal-background.png') no-repeat;
            background-size: cover;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            height: 90px;
            padding: 0 4rem;

            &#whatsapp-header {
                background: url('../images/scroll-modal-wpp-background.png') no-repeat;
                background-size: cover;
            }
            img {
                width: 100%;
                max-width: 40px;
            }
            h2 {
                color: #d3e6f1;
                font-size: 1rem;
                line-height: 1.25;
                text-transform: uppercase;
                text-align: right;
            }
        }

        .form-success {
            display: none;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            max-height: 300px;
            padding: 2rem;
      
            svg {
              color: #04103F;
              font-size: 35px;
            }
      
            h5 {
              color: #000000;
              font-size: 25px;
              text-align: center;
              margin-top: .5rem;
            }
          }
        
        .modal-form {
            display: flex;
            flex-direction: column;
            
            align-items: center;
            
            padding: 2rem 0;
            
            .modal-field {
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 600px;
                
                box-sizing: border-box;
                
                padding: 0.6rem;
                div {
                    width: 35%;
                    font-size: initial;
                    .modal-icon {
                        color: #051550;
                        font-size: 10px;
                        margin-right: 5px;
                    }
                    
                    label {
                        color: #8895a2;
                        font-size: 13px;
                        font-weight: 600;
                    }
                }
                
                input {
                    background-color: #e3e9ef;
                    border: 0;
                    border-radius: 20px;
                    width: 535px;
                    height: 40px;
                    margin-left: auto;
                    font-size: 14px;
                    padding: 0 1rem;
                    
                    &:focus {
                        outline: none;
                    }

                    &.error {
                        border: 1px solid #ff0000;
                    }
                }
            }
            button {
                background-color: rgb(0, 255, 102);
                padding: 1rem 4rem;
                margin-top: 1rem;
                border: 0;
                border-radius: 20px;
                color: rgb(39, 65, 91);
                font-size: 15px;
                text-transform: uppercase;
                font-weight: bold;
                
                cursor: pointer;
                
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
  .chat-modal {
      .modal-content {
          position: relative;
          .modal-close {
              display: initial;
              svg {
                  font-size: 14px;
                  position: absolute;
                  right: 5px;
                  top: 3px;
              }                
          }

          .modal-header {
              padding: 0 2rem;
              
              h2 {
                  font-size: 0.75rem;
                  margin-left: 0.5rem;
              }
          }

          .form-success {
            svg {
              font-size: 25px;
            }
    
            h5 {
              font-size: 18px;
            }
          }
          
          .modal-form {
              .modal-field {
                  flex-direction: column;
                  div {
                      width: initial;
                      margin-bottom: 0.5rem;
                  }
                  label {
                      font-size: 0.72rem;
                  }
                  input {
                      width: 95%;
                      padding: 0 10px;
                      margin: 0;
                  }
              }
              button {
                  padding: 1rem 3rem;
              }
          }
      }
  }
}