#measure{
    &{
        width: 100%;
        display: block;
        background-image: url('./../images/measure-bg.png');        
        // background-attachment: fixed;
        background-position: center;
        background-size: cover;
        padding: 7rem 0;
    }
    .flex{
        &{
            justify-content: space-between;
            align-items: center;
        }
    }    
    p{
        &{
            width: 70%;
        }
        
        strong{
            &{
                color: #FFF;
                font-size: 42px;
                line-height: 1;
                font-family: 'Playfair Display SC', serif;
                font-weight: normal;
            }
            span{
                &{
                    color: inherit;
                    font-family: inherit;
                    display: block;
                    font-size: 32px;
                }
            }
        }
    }
    a{
        &{
            display: inline-block;
            color: #04103e;
            font-size: 14px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background-color: #ffc500;
            box-shadow: 0 6px #b98c00;
            position: relative;
            border: 0;
            padding: 1rem 2rem;
            text-transform: uppercase;
            cursor: pointer;
        }
        &:hover{
            top: 2px;
            box-shadow: 0 2px #b98c00;
        }
    }
}
@media screen and (max-width: 999px) {
    #measure {
        .flex{
            &{
                flex-direction: column;
                padding: 0;
            }
            p{
                &{
                    width: 100%;
                    text-align: center;
                }
                br{
                    &{
                        display: none;
                    }
                }
            }
            a{
                &{
                    margin: {
                        top: 1rem;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    #measure{
        p{
            strong{
                &, span{
                    font-size: 24px;
                }
            }
        }
    }
}