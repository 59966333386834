#benefits{
    &{
        width: 100%;
        display: block;
        background-color: #e5f3f9;
        padding: 5rem 0;
    }
    .text{
        &{
            width: 100%;
            display: block;
            max-width: 680px; 
            margin: auto;               
            text-align: center;
        }

        h2, h3{
            color: #04103e;
            display: block;
        }
        h2{
            &{
                font-size: 52px;
                font-family: 'Playfair Display SC', serif;
                font-weight: normal;
            }
        }
        h3{
            &{
                font-size: 18px;
                margin: 1rem 0;
                font-weight: normal;
            }
        }        
    }
    .column{
        &{
            display: flex;
            justify-content: space-between;
        }
        p{
            &{
                width: 45%;
                color: #26300d;
                font-size: 14px;
                text-align: justify;
            }
        }
    }
    ul{
        &{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;
            margin: {
                top: 3rem;
            }
        }
        li{
            &{
                width: 24%;
                margin: 1rem 0;
            }
            figure{
                &{
                    text-align: center;
                }
                i{
                    &{
                        width: 105px;
                        height: 100px;
                        display: block;
                        margin: auto;
                        background-image: url('./../images/benefits-sprite.png');
                    }
                    &.vigilancia{
                        background-position: 0 0;
                    }
                    &.quadra-tenis{
                        background-position: 566px 0px;
                    }
                    &.campo-society,
                    &.quadra-poliesportiva{
                        &{
                            background-position: 107px 100px;
                        }
                    }
                    &.lagos{
                        &{
                            background-position: 106px 0px;
                        }
                    }
                    &.energizacao{
                        background-position: 0 100px;
                    }
                    &.sistema-viario{
                        background-position: 571px 100px;
                    }
                    &.alamedas{
                        &{
                            background-position: 334px 100px;
                        }
                    }
                }
                figcaption{
                    &{
                        display: block;
                        margin: {
                            top: 0.5rem;
                        }
                    }
                    h4{
                        &{
                            font-weight: normal;
                            color: #04103e;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    p{
        &{
            display: block;
            text-align: center;
            margin: {
                top: 3rem;
            }
        }
        a{
            &{
                display: inline-block;
                color: #04103e;
                font-size: 14px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background-color: #ffc500;
                box-shadow: 0 6px #b98c00;
                position: relative;
                border: 0;
                padding: 0.5rem 2rem;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: bold;
            }
            &:hover{
                top: 2px;
                box-shadow: 0 2px #b98c00;
            }
        }
    }
}
@media screen and (max-width: 767px){
    #benefits {
        .column{
            &{
                flex-direction: column;
            }
            p{
                &{
                    width: 100%;
                    margin: 1rem 0;
                }
            }
        }
        ul {
            li{
                &{
                    width: 32%;
                }
            }
        }
    }
}
@media screen and (max-width: 600px){
    #benefits{
        &{
            padding: 1rem 0;
        }
        .text {
            h2{
                &{
                    font-size: 24px;
                }
            }
            p{
                &{
                    margin: {
                        top: 1rem;
                    }
                }
            }
        }
        ul{
            li{
                &{
                    width: 49%;
                }
            }
        }
    }
}