
#maps {
  & {
    width: 100%;
    display: block;
    background-image: url("./../images/maps-bg.png");
    background-size: cover;
    padding: {
      bottom: 2rem;
    }
  }
  nav {
    & {
      transition: 1s;
    }
  }
  h1 {
    & {
      width: 100%;
      display: block;
      text-align: center;
      margin: {
        top: 10rem;
      }
      color: #fff;
      font-size: 38px;
      font-family: "Playfair Display SC", serif;
      font-weight: normal;
    }
  }
  h2 {
    & {
      display: block;
      text-align: center;
      color: #fff;
      font-size: 22px;
      font-weight: normal;
      margin: {
        top: 1rem;
      }
    }
  }

  .content-maps {
    & {
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
    .active {
      &{
        display: flex !important;

      }
    }
    
    .hidden {
      &{
        display: none !important;

      }
    }

    .maps {
      & {
        width: 100%;
        display: block;
        position: relative;
      }

      .merge {
        & {
          img {
            & {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              opacity: 0;
              margin: auto;
              max-width: 100%;
              transition: opacity 0.7s ease-in-out;
            }
            &.full {
              position: static;
              opacity: 1;
            }
            &.js-active {
              opacity: 1;
            }
          }
        }
      }

      .map {
        & {
          // width: 100%;
          display: block;
        }
        img {
          & {
            margin: auto;
            max-width: 545px;
          }
        }
      }
      .thumb {
        & {
          top: 0;
          position: absolute;
          display: block;
        }
        img {
          & {
            display: none;
          }
          &.arrow {
            & {
              display: block;
              max-width: 490px;
            }
          }
        }
        figcaption {
          p {
            & {
              color: #61702a;
              font-size: 14px;
              text-align: left;
              font-weight: 300;
              display: none;
            }
          }
        }
      }
    }

    .legend {
      & {
        width: 30%;
        display: block;
        position: absolute;
        right: 17%;
      }
      .aligndiv {
        display: flex;
        justify-content: flex-end;
      }
      p {
        & {
          display: block;
          text-align: end;
          font-size: 12px;
          width: 65%;
          padding: 5.5% 2%;
        }
        strong {
          & {
            display: block;
            color: #fff;
            font-size: 22px;
            font-family: "Playfair Display SC", serif;
          }
        }
      }
      ul {
        & {
          display: flex;
          justify-content: flex-end;
          // margin: {
          //   top: 1rem;
          // }
          flex-flow: wrap;
        }
        &:last-child {
          & {
            margin: {
              top: 0;
            }
          }
        }
        li {
          & {
            margin: 0.25rem 0;
          }
          a {
            & {
              width: 25px;
              height: 25px;
              border-radius: 100%;
              display: block;
              margin: 0 0.15rem;
              border: 2px solid #fff;
              color: #fff;
              background-color: #4ab64e;
              text-align: center;
              font-size: 0.7rem;
              line-height: 2;
              font-weight: bold;
            }
            &.active {
              & {
                padding-left: 23%;
                background-color: #fff200;
                color: #427f00;
                border-color: #427f00;
              }
            }
          }
        }
      }
    }
  }
  .title {
    top: 33% !important;
    left: 4% !important;
  }
  .container-button {
    position: absolute;
    width: 20%;
    top: 40%;
    left: -26%;
    justify-content: center;
    .button-title {
      & {
        color: white;
        margin: auto;
        font-size: 12px;
      }
    }
    .button {
      & {
        width: 80%;
        display: block;
        margin: 0.3rem 0;
        position: relative;
        white-space: nowrap;
        border: 0;
        background-color: #fff;
        color: #18043a;
        font-size: 12px;
        left: 150%;
        font-weight: bold;
        border-radius: 10px;
        padding: 0.7rem 1rem;
        cursor: pointer;
        transition: background 0.7s ease-in-out;
        outline: none;
      }
      &.js-active {
        & {
          color: #fff;
          background-color: #18043a;
        }
      }
    }
  }
}



@media screen and (max-width: 1180px) {
  #maps {
    .container-button {
      position: unset;
      width: 100%;
      align-items: center;
      margin: auto;
      margin-top: 5%;

      display: flex;
      flex-direction: column;
      .button {
        & {
          width: 60%;
          position: unset;
        }
      }
    }
    .content-maps {
      & {
        flex-direction: column;
        justify-content: center;
        
      }
      .legend {
        position: unset;
        .aligndiv {
          justify-content: center;
          p {
            padding: 2%;
            width: auto;
          }
        }
      }
      .maps {
        .map {
          img {
            & {
              max-width: 100%;
            }
          }
        }
        .thumb {
          & {
            width: 100%;
            position: static;
            background-color: #fff;
            padding: 0.5rem;
            border-radius: 5px;
            width: 100%;
            max-width: 360px;
            margin: 1rem auto;
          }
          figcaption {
            p {
              & {
                display: block;
              }
            }
          }
          img {
            & {
              display: block;
              max-width: 100%;
            }
            &.arrow {
              & {
                display: none;
              }
            }
          }
        }
      }
      nav {
        transition: 0.5s;
      }
      .legend {
        & {
          width: 100%;
          text-align: center;
        }
        ul {
          & {
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 999px) {
  #maps {
    h1 {
      & {
        margin: {
          top: 2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  #maps {
    .content-maps {
      .legend {
        position: unset;
      }
      .button {
        position: unset;
      }
    }

    .container-button {
      position: unset;
      width: 100%;
      .button {
        & {
         // width: 100%;
        }
      }
    }
    h1 {
      & {
        font-size: 32px;
      }
      br {
        & {
          display: none;
        }
      }
    }
  }
}
