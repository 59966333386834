#congratulations{
    &{
        width: 100%;
        display: block;
        margin: 10rem 0;
    }
    .text{
        &{
            width: 100%;
            display: block;
            text-align: center;
            color: #04103e;
        }
        i{
            &{
                font-size: 72px;
            }
        }
        h1{
            &{
                display: block;
                font-size: 32px;
                text-transform: uppercase;
                margin: 2rem 0;
                color: inherit;
            }
        }
        p{
            &{
                display: block;
                color: inherit;
            }
            i{
                &{
                    font-size: 72px;
                }
            }
            a{
                &{
                    width: auto;
                    display: inline-block;
                    color: inherit;
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                    border: 0;
                    cursor: pointer;
                    margin: {
                        top: 1rem;
                    }
                    padding: 0.5rem 1rem;
                    border-radius: 10px;
                    background-color: #ffc500;
                    box-shadow: 0 6px #b98c00;
                    position: relative;
                }
                &:hover{
                    top: 2px;
                    box-shadow: 0 2px #b98c00;
                }
            }
        }
    }
}
@media screen and (max-width: 999px){
    #congratulations{
        &{
            margin: 5rem 0;
        }
    }
}
@media screen and (max-width: 600px){
    #congratulations {
        &{
            margin: 3rem 0;
        }
        .text {
            h1{
                &{
                    font-size: 24px;
                }
            }
            p {
                a{
                    &{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}