#senpar{
    &{
        width: 100%;
        display: block;
        margin: 2rem 0 0 0;
    }
    figure{
        &{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem 5rem;
            background-color: #ecf7fb;
        }
        figcaption{
            &{
                width: 70%;
                color: #04103e;
                font-size: 14px;
                padding: {
                    left: 3rem;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    #senpar{
        figure{
            &{
                flex-direction: column;                
                padding: 0.5rem;
            }
            figcaption{
                &{
                    width: 100%;
                    display: block;
                    text-align: center;
                    margin: {
                        top: 0.5rem;
                    }
                    padding: {
                        left: 0;
                    }
                }
            }
        }
    }
}

#senpar .faixa-institucional-mobile{
    display: none;
}

#senpar .faixa-institucional-desktop {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    #senpar .faixa-institucional-desktop {
        display: none;
    }
    #senpar .faixa-institucional-mobile{
        display: block;
        width: 100%;
    }    
}