#shedule{
    &{
        width: 100%;
        display: block;
        padding: 1.5rem 0;
        background-color: #04103e;
    }
    p{
        &{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &.small{
            strong{
                &{
                    font-size: 32px;
                }
            }
        }
        strong{
            &{
                color: #FFF;
                font-size: 42px;
                font-family: 'Playfair Display SC', serif;
                font-weight: normal;
            }
        }
        a{
            &{
                display: inline-block;
                color: #04103e;
                font-size: 14px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background-color: #ffc500;
                box-shadow: 0 6px #b98c00;
                position: relative;
                border: 0;
                padding: 1rem 2rem;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: bold;
            }
            &:hover{
                top: 2px;
                box-shadow: 0 2px #b98c00;
            }
        }
    }
}
@media screen and (max-width: 999px){
    #shedule{
        p{
            &{
                flex-direction: column;
            }
            &.small strong,
            strong{
                &{
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                }
            }
            a{
                &{
                    margin: {
                        top: 0.5rem;
                    }
                }
            }
        }
    }
}