#videoMain{
    &{
        width: 100%;
        height: 47.9vw;
        display: block;
        cursor: pointer;
        position: relative;
        pointer-events: none;
    }    
    figure{
        &{
            width: 100%;
            height: 101%;
            display: block;
            position: relative;
        }
        iframe{
            &{
                width: 100%;
                height: 100%;
                display: block;
                pointer-events: none;
                margin: auto;
                position: relative;
                transform: translateY(-60px);
            }
        }
    }
    .card{
        &{
            width: 100%;
            max-width: 900px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #04103e;
            padding: 2rem;
            box-sizing: border-box;
            position: absolute;
            bottom: -60px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 3;
            box-shadow: 0 0 25px #000;
        }
        p{
            &{
                text-align: center;
                color: #ffc500;
                font-size: 22px;
                font-family: 'Playfair Display SC', serif;
            }
            span{
                &{
                    display: block;
                    color: #FFF;
                    font-family: inherit;
                }
                &.middle{
                    font-size: 20px;
                }
                &.bottom{
                    font-size: 16px;
                }
            }
        }
        a{
            &{
                display: inline-block;
                color: #04103e;
                font-size: 14px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background-color: #ffc500;
                box-shadow: 0 6px #b98c00;
                position: relative;
                border: 0;
                padding: 0.5rem 2rem;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: bold;
            }
            &:hover{
                top: 2px;
                box-shadow: 0 2px #b98c00;
            }
        }
    }
}
@media screen and (max-width: 999px) {
    #videoMain {
        &{
            height: 380px;
            background-size: cover;
            background-position: center bottom;
            background-image: url('./../images/slider-bg.png');
        }
        figure{
            &{
                display: none;
            }            
        }
        .card{
            &{
                display: none;
            }
        }
        // .card{
        //     &{
        //         width: 98%;
        //         flex-direction: column;
        //         position: static;
        //         margin: {
        //             top: -2rem;
        //             bottom: 1.5rem;
        //         }
        //         margin: 1rem 0;
        //         background-color: rgba(4, 16, 62, 0.5);
        //         box-shadow: unset;
        //     }
        //     p{
        //         &,
        //         span.middle,
        //         span.bottom{
        //             &{
        //                 font-size: 14px;
        //             }
        //         }
        //     }
        //     a{
        //         &{
        //             margin: {
        //                 top: 0.25rem;
        //             }
        //         }
        //     }
        // }
    }
}