.anapro-chat-img{
    &{
        position: fixed;
        right: 10px;
        bottom: 10px;
        /* background: white; */
        width: 250px;
        height: 140px;
        cursor: pointer;
        z-index: 9999;
    }

    img{
        max-width: 100%;
    }
}