.timeline {
    & {
        width: 100%;
        display: block;
        font-family: "Open Sans",sans-serif;
    }

    h3 {
        margin: 0;
    }

    * {
        box-sizing: border-box;
    }

    &__achivement-carousel {
        & {
            margin-top: 2rem;
            display: none;
        }

        .timeline__text-wrapper {
            background: white;
            color: black;
            text-align: center;
            display: flex;
            align-items: center;
            margin: 1%;
            width: 270px !important;
            padding: 2%;
            color: black;
            font-weight: bold;

            p {
                & {
                    font-size: 13px;
                }
            }
        }

        .slick-slide {
            height: auto;
            display: flex !important;
            justify-content: center;
        }

        .slick-arrow {
            & {
                width: 60px;
                outline: none;
                border: 0;
                height: 60px;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 2;
                font-size: 0;
                background: transparent;
                color: white;
                text-align: center;
            }

            &::before {
                font-family: 'Font Awesome\ 5 Free';
                font-size: 24px;
                font-weight: 600;
            }

            &.slick-prev {
                left: 0%;

                &::before {
                    content: '\f053 ';
                }
            }

            &.slick-next {
                right: 0%;

                &::before {
                    content: '\f054';
                }
            }
        }
    }

    &__year {
        & {
            width: 100%;
            display: block;

            // height: 500px;
            background-size: cover;
            position: relative;
            padding: 2rem 0;
            display: flex;
            background-position-x: center;
            height: 500px;
        }

        img {
            & {
                max-width: 100%;
            }
        }

        .carousel .slide {
            & {
                background: transparent;
            }

            .timeline__text-wrapper {
                & {
                    // background: white;
               
                }

                p {
                    color: black;
                    background: white;
                    width: fit-content;
                    margin: auto;
                    padding: 6%;
                    font-size: 20px;
                }
            }
        }

        div {
            box-sizing: border-box;
        }

        &::before {
            content: '';
            width: 1px;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        &::after {
            content: '';
            width: 25px;
            height: 25px;
            display: block;
            border-radius: 100%;
            background-color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        &-card {
            width: 100%;
            display: flex;
            max-width: 700px;
            margin: 0 auto;
            justify-content: space-between;
            align-items: center;
            color: #fff;
        }

        &-number,
        &-text {
            & {
                width: auto;
                padding: 0 2rem;
            }
        }

        &-number {
            & {
                display: block;
            }

            h3 {
                width: 100%;
                display: flex;
                min-width: 300px;
                align-items: center;
                justify-content: center;
                font-size: 62px;
                font-family: 'Trajan';
                font-weight: normal;
            }

            span {
                font-size: 188px;
            }
        }

        &-text {
            & {
                font-size: 16px;
                line-height: 1.3;
            }

            strong {
                & {
                    width: 100%;
                    display: block;
                    font-size: 23px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }

        &--end {
            &::before {
                height: 15%;
            }

            &::after {
                top: 16.5%;
                bottom: unset;
                transform: translateY(-50%);
            }

            .timeline__year-card {
                flex-flow: wrap;
                max-width: 1260px;
                height: min-content;
                padding-top: 27px;
            }

            .timeline__year-number {
                & {
                    width: 50%;
                    margin: 0;
                    outline: none;
                    padding: 0 20px;
                    border: 0;
                    font-size: 100%;

                    // font: inherit;
                    font-family: 'Trajan';
                    vertical-align: baseline;
                    box-sizing: border-box;
                }

                h3 {
                    margin: 0;
                    outline: none;
                    padding: 0;
                    border: 0;
                    font-size: 100%;

                    // font: inherit;
                    box-sizing: border-box;
                    font-size: 28px;
                    justify-content: flex-end;
                    margin: 0;
                    font-family: 'Trajan';
                }
            }

            .timeline__year-diagram {
                width: 100%;
                margin: 3rem 0;

                img {
                    max-width: 100%;
                    padding: 0 1rem;
                    box-sizing: border-box;
                }
            }
        }
    }
}

@media (max-width: 999px) {
    .timeline {
        &__year {
            & {
                height: auto;
                padding: 3rem 0;
            }

            &::before {
                right: unset;
                left: 2rem;
            }

            &::after {
                right: unset;
                left: 1.25rem;
            }

            &-number {
                &,
                span {
                    font-size: 32px;
                }
            }

            &-number,
            &-text {
                width: 100%;
                padding: {
                    left: 3.5rem;
                    right: 1rem;
                };

                h3 {
                    min-width: unset;
                    max-width: 100px;
                    justify-content: flex-start;
                }
            }

            &-card {
                flex-direction: column;
            }

            &--end {
                & {
                    flex-direction: column-reverse;
                    padding-top: 0;
                    height: 200px;
                }

                .timeline__year-number {
                    h3 {
                        padding: {
                            left: 40px;
                        };
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .timeline {
        &__year {
            &-text {
                font-size: 14px;

                strong {
                    font-size: 18px;
                }
            }

            &--end {
                & {}

                .timeline__year-number {
                    h3 {
                        position: absolute;
                        width: fit-content;

                        // padding: 0px 50px;
                        top: 15px;
                    }
                }
            }
        }
    }
}
