
#development{
    &{
        width: 100%;
        display: block;
        transform: translateY(-60px);
    }
    .content{
        &{
            position: relative;
            z-index: 2;
        }
    }
    .text{
        &{
            background-color: #ecf6fb;
            text-align: center;
            padding: 5rem 0;
            margin: auto;
        }
        h1, h2{
            color: #04103e;
            display: block;
        }
        h1{
            &{
                font-family: 'Playfair Display SC', serif;
                font-size: 48px;
                margin: {
                    top: 4rem;
                }
                font-weight: normal;
            }
        }
        h2{
            &{
                font-size: 18px;
                margin: 1rem 0;
                font-weight: normal;
            }
        }
        p{
            &{
                display: block;
                color: #26300d;
                font-size: 14px;
                margin: 0;
            }
        }
    }
    .owl-carousel{
        img{
            max-height: 765px !important;
        }
        .owl-nav{
            .owl-prev,
            .owl-next{
                &{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    border: 1px solid #FFF;
                    color: #FFF;
                    width: 50px;
                    height: 50px;
                    display: block;
                    margin: auto;
                    font-size: 24px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 2;
                }
            }
            .owl-prev{
                &{
                    left: 20%;
                }
            }
            .owl-next{
                &{
                    right: 20%;
                }
            }
        }
        .owl-dots{
            &{
                width: 100%;
                max-width: 815px;
                display: flex;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                bottom: -50px;
            }
            .owl-dot{
                &{
                    width: 100px;
                    height: 100px;
                    display: block;
                    background-color: #FFF;
                    border-radius: 10px;
                    margin: 0 0.5rem;
                    transition: background 0.7s ease;                    
                    position: relative;
                    box-shadow: 0 10px 10px 2px lightgrey;
                }
                span{
                    &{
                        display: block;
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        background-image: url('./../images/development-sprite.png');
                        background-repeat: no-repeat;
                        font-size: 14px;
                        text-align: center;
                    } 
                }
                &:nth-child(1){
                    &.active{
                        span{
                            &{                                
                                height: 50px;
                            }
                            &::before{
                                content: 'Vigilância 24 horas';
                            }
                        }
                    }
                    span{
                        &{
                            background-position: 0 0;
                            width: 37px;
                            height: 45px; 
                        }
                    }
                }
                &:nth-child(2){
                    &.active{
                        span{
                            &::before{
                                content: '4 Quadras de Tênis';
                            }
                        }
                    }
                    span{
                        &{
                            background-position: -109px 0;     
                                                 
                        }
                    }
                }
                &:nth-child(3){
                    &.active{
                        span{
                            &{
                                height: 70px;
                            }
                            &::before{
                                content: 'Campo de Futebol Society';
                            }
                        }
                    }
                }
                &:nth-child(3),
                &:nth-child(8){
                    span{
                        background-position: -225px 0;
                    }
                }
                &:nth-child(4){
                    &.active{
                        span{
                            &{
                                height: 35px;
                            }
                            &::before{
                                content: 'Lagos';
                            }
                        }
                    }
                    span{
                        background-position: -339px 0;
                    }
                }
                &:nth-child(5){
                    &.active{
                        span{
                            &::before{
                                content: 'Energização Subterrânea';
                            }
                        }
                    }
                    span{
                        background-position: -456px 0;
                    }
                }
                &:nth-child(6){
                    &.active{
                        span{
                            &{
                                height: 75px;
                            }
                            &::before{
                                content: 'Sistema Viário Inteligente';
                            }
                        }
                    }
                    span{
                        background-position: -572px 0;
                    }
                }
                &:nth-child(7){
                    &.active{
                        span{
                            &::before{
                                content: 'Alamedas exclusivas';
                            }
                        }
                    }
                    span{
                        background-position: -687px 0;
                    }
                }

                &:nth-child(8){
                    &.active{
                        span{
                            &::before{
                                content: 'Quadra poliesportiva';
                            }
                        }
                    }
                }
                
                &.active{
                    &{
                        background-color: #04103e;
                    }
                    span{
                        &{
                            width: 100%;
                            padding: 0.5rem;
                            background-image: none;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
    p{
        &{
            width: 100%;
            display: block;
            text-align: center;
            margin: {
                top: 5rem;
            }
        }
        a{
            &{
                display: inline-block;
                color: #04103e;
                font-size: 14px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background-color: #ffc500;
                box-shadow: 0 6px #b98c00;
                position: relative;
                border: 0;
                padding: 0.5rem 2rem;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: bold;
            }
            &:hover{
                top: 2px;
                box-shadow: 0 2px #b98c00;
            }
        }
    }
}
@media screen and (max-width: 1366px) {
    #development {
        .owl-carousel {
            .owl-nav {
                .owl-next{
                    &{
                        right: 0;
                    }
                }
                .owl-prev{
                    &{
                        left: 0;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 999px) {
    #development {
        &{
            transform: unset;
        }
        .text {
            &{
                max-width: unset;
                padding: 2rem 0;
            }            
        }
        .owl-carousel {
            
            .owl-nav{
                &{
                    display: none;
                }
            }
            .owl-dots {
                &{
                    justify-content: center;
                    bottom: -1.5rem;
                }
                .owl-dot{
                    &{
                        width: 10px;
                        height: 10px;
                        border-radius: 0;                        
                        background-color: lightgrey;
                    }
                    span{
                        &{
                            background-image: none;
                            font-size: 0;
                        }
                    }
                }
            }
        }
        p{
            &{
                margin: {
                    top: 2.5rem;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    #development {
        .text {
            h1{
                &{
                    font-size: 24px;
                }
            }
            h2{
                &{
                    font-size: 16px;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    #development {
        .text {
            h1{
                &{
                    font-size: 24px;
                    margin: {
                        top: 2.5rem;
                    }
                }
            }           
        }
    }
}