#header {
    & {
        width: 100%;
        display: block;
        padding: 0.5rem 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }

    &.transparence {
        & {
            background-color: #04103e;
            position: relative;
        }

        figure {
            &.logo {
                img {
                    & {
                        filter: grayscale(1) brightness(100);
                    }
                }
            }
        }

        nav {
            ul {
                li {
                    a {
                        & {
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }

    &.white {
        figure {
            &.logo {
                img {
                    & {
                        filter: grayscale(1) brightness(100);
                    }
                }
            }
        }

        nav {
            ul {
                li {
                    a {
                        & {
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }

    .flex {
        & {
            align-items: center;
            justify-content: space-between;
        }
    }

    figure {
        &.logo {
            & {
                width: 25%;
            }

            a {
                & {
                    display: block;
                }
            }
        }
    }

    nav {
        & {
            width: 70%;
        }

        button {
            & {
                display: none;
                background-color: #ffc500;
                color: #04103e;
                padding: 0.5rem 1rem;
                border: 0;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: bold;
                border-radius: 5px;
            }

            svg {
                & {
                    padding: {
                        right: 0.5rem;
                    };
                }
            }
        }

        ul {
            & {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            li {
                & {
                    width: 32%;
                }

                &.mobile {
                    & {
                        display: none;
                        background-color: #04103e;
                        padding: 0.5rem 1rem;
                        justify-content: space-between;
                        margin: {
                            bottom: 1rem;
                        };
                    }

                    figure {
                        img {
                            & {
                                filter: grayscale(1) brightness(100);
                                max-width: 100px;
                            }
                        }

                        a {
                            & {
                                padding: 0;
                                border: 0;
                            }
                        }
                    }
                }

                a {
                    & {
                        display: block;
                        text-align: center;
                        color: #04103e;
                        text-transform: uppercase;
                        font-family: 'Trajan';
                        font-weight: bold;
                    }

                    &.corretor {
                        & {
                            font-size: 12px;
                            background-color: #ffc500;
                            box-shadow: 0 3px #b98c00;
                            position: relative;
                            color: #04103e;
                            text-transform: uppercase;
                            border-radius: 5px;
                            padding: 0.25rem;
                        }

                        &:hover {
                            & {
                                top: 1px;
                                box-shadow: 0 1px #b98c00;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 999px) {
    #header {
        & {
            background-color: rgb(4, 16, 62) !important;
            padding: 0.5rem 0;
            position: relative;
        }

        figure {
            &.logo {
                img {
                    filter: grayscale(1) brightness(100);
                    max-width: 150px;
                }
            }
        }

        nav {
            & {
                overflow: hidden;
                text-align: right;
            }

            button {
                & {
                    display: inline-block;
                }
            }

            ul {
                & {
                    transform: translateX(100%);
                    transition: transform 0.7s ease, box-shadow 0.7s ease;
                    position: fixed;
                    top: 0;
                    right: 0;
                    width: 50%;
                    height: 100vh;
                    background-color: #FFF;
                    flex-direction: column;
                    justify-content: flex-start;
                }

                &.open {
                    & {
                        transform: translateX(0);
                        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75);
                    }
                }

                li {
                    & {
                        width: 100%;
                        padding: 0 1rem;
                    }

                    &.mobile {
                        & {
                            display: flex;
                        }
                    }

                    a {
                        & {
                            color: #04103e !important;
                            padding: 1rem 0;
                            text-align: left;
                            border: {
                                bottom: 1px solid lightgray;
                            };
                        }

                        &.corretor {
                            & {
                                margin: {
                                    top: 0.5rem;
                                };
                                padding: 0.5rem 1rem;
                            }
                        }

                        br {
                            & {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #header {
        figure {
            &.logo {
                img {
                    & {
                        max-width: 100px;
                    }
                }
            }
        }

        nav {
            ul {
                & {
                    width: 90%;
                }
            }
        }
    }
}
