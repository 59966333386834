#locationmap{
    &{
        width: 100%;
        display: block;
        background-image: url('./../images/locationmap-bg.png');
        background-size: cover;
        padding: 10rem 0 40rem 0;
        text-align: center; 
        position: relative;
        background-position: bottom left;
    }
    &::after{
        &{
            content: '';
            width: 100%;
            display: block;
            height: 198px;
            background-image: url('./../images/locationmap-after.png');
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    h2{
        &{
            width: 100%;
            display: block;
            color: #04103e;
            font-size: 52px;
            font-family: 'Playfair Display SC', serif;
            font-weight: normal;
        }
    }
    h3{
        &{
            margin: 1.5rem 0;
            display: block;
            color: #04103e;
            font-size: 16px;
            font-weight: normal;
        }
    }
    p{
        &{
            display: block;
            position: relative;
            z-index: 2;
        }
        a{
            &{
                display: inline-block;
                color: #04103e;
                font-size: 14px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background-color: #ffc500;
                box-shadow: 0 6px #b98c00;
                position: relative;
                border: 0;
                padding: 1rem 2rem;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: bold;
            }
            &:hover{
                top: 2px;
                box-shadow: 0 2px #b98c00;
            }
        }
    }
}
@media screen and (max-width: 999px) {
    #locationmap{
        &{
            background-image: url('././../images/locationmap-bg-no-text.png');
            padding: 10rem 0;
        }        
    }
}
@media screen and (max-width: 600px) {
    #locationmap{
        &{
            padding: 2rem 0 7rem 0;
        }
        h2{
            &{
                font-size: 24px;
            }
        }
    }
}