#footer{
    &{
        width: 100%;
        display: block;
        background-color: #1d2751;
        padding: 1.5rem 0;
    }
    .flex{
        &{
            justify-content: space-between;
            align-items: center;
        }
    }
    p{
        &{
            display: block;
            text-align: center;
            text-transform: uppercase;
            color: #FFF;
            font-size: 12px;
        }
    }
    .share{
        &{
            display: flex;
        }
        a{
            &{
                color: #FFF;
                font-size: 32px;
                margin: 0 0.5rem;
            }
        }
    }
}
@media screen and (max-width: 999px){
    #footer{
        &{
            margin: {
                bottom: 5rem;
            }
        }
    }
}
@media screen and (max-width: 767px){
    #footer{
        .flex{
            &{
                flex-direction: column;
            }
        }
        p{
            &{
                margin: {
                    bottom: 0.5rem;
                }
            }
        }
    }
}