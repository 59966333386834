#places{
    &{
        width: 100%;
        display: block;
        margin: 5rem 0;
    }
    .flex{
        &{
            margin: 2rem 0;
            justify-content: space-between;
        }
    }
    h2{
        &{
            display: block;
            text-align: center;
            font-size: 52px;
            color: #04103e;
            font-family: 'Playfair Display SC', serif;
            font-weight: normal;
        }
    }
    #g-maps{
        &{
            width: 60%;
            position: relative;
        }
    }
    aside{
        &{
            width: 40%;
            background-color: #04103e;
            padding: 2rem 1rem;
        }
        nav{
            &{
                display: block;
            }
            p{
                &{
                    display: block;
                    color: #FFF;
                    font-size: 14px;
                }
            }
            ul{
                &{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: wrap;
                    margin: {
                        top: 1rem;
                    }
                }
                li{
                    &{
                        width: 49%;
                    }
                    a{
                        &{
                            display: flex;
                            align-items: center;
                            color: #FFF;
                            padding: 1rem;
                            font-size: 14px;
                        }
                        i{
                            &{
                                background-image: url('./../images/places-sprites.png');
                                background-repeat: no-repeat;
                                display: block;
                                margin: {
                                    right: 0.5rem;
                                }
                            }
                            &.places-bancos {
                                width: 25px;
                                height: 29px;
                                background-position: -1px -1px;
                            }
                            
                            &.places-carrinho {
                                width: 26px;
                                height: 27px;
                                background-position: -28px -1px;
                            }
                            
                            &.places-diversao {
                                width: 26px;
                                height: 26px;
                                background-position: -28px -30px;
                            }
                            
                            &.places-escolas {
                                width: 20px;
                                height: 26px;
                                background-position: -56px -1px;
                            }
                            
                            &.places-farmacia {
                                width: 29px;
                                height: 29px;
                                background-position: -78px -1px;
                            }
                            
                            &.places-hoteis {
                                width: 26px;
                                height: 26px;
                                background-position: -56px -32px;
                            }
                            
                            &.places-padaria {
                                width: 26px;
                                height: 26px;
                                background-position: -84px -32px;
                            }
                            
                            &.places-shopping {
                                width: 26px;
                                height: 26px;
                                background-position: -1px -60px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    #places {
        .flex{
            &{
                flex-direction: column;
            }
            #g-maps,
            aside{
                &{
                    width: 100%;
                }
            }
            #g-maps{
                &{
                    height: 360px;
                }
            }
            aside{
                nav {
                    p{
                        &{
                            text-align: center;
                        }
                    }
                    ul {
                        li {
                            a{
                                &{
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    #places {
        &{
            margin: 1rem 0;
        }
        h2{
            &{
                font-size: 24px;
            }
        }
    }
}
@media screen and (max-width: 380px) {
    #places {
        aside {
            nav {
                ul {
                    li{
                        &{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}