#closeEverything{
    &{
        width: 100%;
        display: block;
        margin: {
            bottom: 5rem;
        }
        position: relative;
    }
    & > figure{
        &{
            width: 100%;
            display: block;
            position: relative;
        }
        &::after{
            &{
                content: '';
                width: 100%;
                display: block;
                height: 198px;
                background-image: url('./../images/locationmap-after.png');
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        img{
            &{
                width: 100%;
            }
        }
    }
    .text{
        &{
            max-width: 980px;
            display: block;
            margin: auto;
            position: absolute;
            top: 15rem;
            left: 0;
            right: 0;
            text-align: center;
        }
        h1{
            &{
                display: block;
                color: #04103e;
                font-size: 52px;
                font-family: 'Playfair Display SC', serif;
                margin: {
                    bottom: 1rem;
                };
                font-weight: normal;
            }
        }
        h2{
            &{
                display: block;
                color: #04103e;
                font-size: 18px;
                font-weight: normal;
            }
        }
    }
    ul{
        &{
            width: 100%;
            max-width: 980px;
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
        li{
            &{
                width: 24%;
                background-color: #FFF;
                border-radius: 10px;
                padding: 1rem;
                box-shadow: 0 0 10px 2px lightgrey;
            }
            figure{
                &{
                    display: block;
                    text-align: center;
                }
                i{
                    &{                        
                        display: block;
                        margin: auto;
                        background-image: url('./../images/closeEverything_sprites.png');
                    }
                    &.aviao{
                        width: 80px;
                        height: 50px;
                        background-position: 0 0;
                    }
                    &.predio{
                        &{    
                            width: 60px;
                            height: 50px;
                            background-position: 58px 0;
                        }
                    }
                    &.parque{
                        &{
                            width: 70px;
                            height: 56px;
                            background-position: 2px 58px;
                        }
                    }
                }
                figcaption{
                    &{
                        display: block;
                        margin: {
                            top: 1rem;
                        }
                    }
                    h3{
                        &{
                            display: block;
                            font-weight: normal;
                            line-height: 1;
                            font-size: 14px;
                            color: #04103e;
                            text-transform: uppercase;
                        }
                        strong{
                            &{
                                font-size: inherit;
                                font-weight: bold;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 999px) {
    #closeEverything{
        &{
            margin: {
                bottom: 2rem;
            }
        }
        .text{
            &{
                top: 5rem;
            }
            h1{
                &{
                    font-size: 22px;
                }
                br{
                    &{
                        display: none;
                    }
                }
            }
        }
        ul{
            &{
                padding: 0 1rem;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    #closeEverything {
        &{
            background-position: center;
            background-size: cover;
            background-image: url('./../images/locationmap-bg-no-text.png');
            padding: 2rem 0;
            margin: {
                bottom: 0;
            }
        }
        figure{
            &.bg{
                &{
                    display: none;
                }
            }
        }
        .text{
            &{
                position: relative;   
                top: 0;     
                padding: 0 1rem;        
            }
        }
        ul{
            &{
                flex-flow: wrap;
                position: relative;
                margin: {
                    top: 2rem;
                }
            }
            li{
                &{
                    width: 49%;
                    margin: 0.5rem 0;
                }
                figure{
                    &{
                        display: flex;
                        justify-content: center;
                    }
                    i{
                        &{
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    #closeEverything {
        ul {
            li{
                &{
                    width: 100%;
                    padding: 0.5rem;
                }
            }

        }
    }
}