
#contact{
    &{
        width: 100%;
        display: block;
        background-image: url('./../images/contact-bg.png');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 3.5rem 0;
        text-align: center;
    }
    h2{
        &{
            display: block;
            color: #FFF;
            font-size: 42px;
            font-family: 'Playfair Display SC', serif;            
            text-shadow: 0 0 10px #000;
            font-weight: normal;
        }
    }
    p{
        &{
            display: block;
            color: #FFF;
            font-size: 18px;
        }
    }
    
    .flex{
        &{
            justify-content: space-between;
            margin: {
                top: 2rem;
            }
        }
    }
    form{
        &{
            width: 73%;
            background-color: rgba(255, 255,255, 0.7);
            padding: 2rem 1rem;
            box-sizing: border-box;
        }
        .group{
            &{
                width: 100%;
                display: block;
                position: relative;
                margin: 0.5rem 0;
            }
            label{
                &{
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-weight: bold;
                    color: #67666a;
                    font-size: 14px;
                }
            }
            input,
            textarea{
                &{
                    width: 100%;
                    background-color: transparent;
                    padding: 1.5rem 0 1rem 0;
                    border: 0;
                    display: block;
                    border: {
                        bottom: 1px solid #67666a;
                    }
                    
                }                
                &:focus{
                   outline: 0;
                }
            }
            .form-error{
                &{
                    display: block;
                    font-size: 12px;
                    text-transform: uppercase;
                    text-align: left;
                    color: red;
                    font-weight: bold;
                }
            }
            textarea{
                &{
                    resize: none;
                    box-sizing: border-box;
                }
            }
            button{
                &{
                    width: 100%;
                    display: block;
                    color: #04103e;
                    font-size: 14px;
                    -webkit-border-radius: 10px;
                    border-radius: 10px;
                    background-color: #ffc500;
                    box-shadow: 0 6px #b98c00;
                    position: relative;
                    border: 0;
                    padding: 1rem;
                    text-transform: uppercase;
                    cursor: pointer;
                    font-weight: bold;
                }
                &[disabled]{
                    &{
                        opacity: 0.25;
                        cursor: not-allowed;
                    }
                    &:hover{
                        top: unset;
                        box-shadow: 0 6px #b98c00;
                    }
                }
                &:hover{
                    top: 2px;
                    box-shadow: 0 2px #b98c00;
                }
            }
        }
        .msgError{
            &{
                width: 100%;
                display: none;                
                background-color: #f8d7da;
                border: 1px solid #f5c6cb;
                padding: 1rem;
                box-sizing: border-box;
                border-radius: 5px;
                margin: {
                    top: 1rem;
                }
            }
            &.isActive{
                &{
                    display: block;
                }
            }
            p{
                &{
                    display: block;
                    color: #721c24;
                    font-size: 14px;
                    text-transform: uppercase;
                }
                strong{
                    &{
                        display: block;
                    }
                }
            }
        }
    }
    .actions{
        &{
            width: 25%;
            background-color: rgba(202, 165, 9, 0.7);
            padding: 2rem 1rem;
            box-sizing: border-box;
        }
        figure{
            &{
                display: block;
            }
            img{
                &{
                    margin: auto;
                    margin: {
                        bottom: 1rem;
                    }
                }
            }
        }
        p{
            &{
                display: block;
                color: #04103e;
            }
            span{
                &{
                    display: block;
                    font-size: 16px;
                }
            }
            strong{
                &{
                    display: block;
                    font-weight: bold;
                    font-size: 26px;
                }
            }
            a{
                &{
                    width: 100%;
                    display: block;
                    margin: {
                        top: 1rem;
                    }
                    -webkit-border-radius: 10px;
                            border-radius: 10px;
                    background-color: #2db203;
                    color: #FFF;
                    text-transform: uppercase;
                    font-size: 14px;
                    box-shadow: 0 6px #227909;
                    position: relative;
                    padding: 1rem 0.5rem;
                }
                &:hover{
                    top: 2px;
                    box-shadow: 0 2px #227909;
                }
            }
        }
    }
}

@media screen and (max-width: 999px){
    #contact{
        .flex{
            &{
                flex-direction: column;
            }
        }
        form,
        .actions{
            &{
                width: 100%;
                margin: 0.5rem 0;
            }
        }
    }
}

@media screen and (max-width: 600px){
    #contact{
        h2{
            &{
                font-size: 24px;
            }
        }
        p{
            &{
                font-size: 16px;
            }
        }
    }
}